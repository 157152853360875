import { useState, useEffect, useRef } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import { FormBuilder } from "@/@ui-lib/molecules"
import { PlanAPI } from "@/api/req/plan_api"
import { SubscriptionAPI } from "@/api/req/subscription_api"
import FlexDatePicker from "@/components/common/date_picker/FlexDatePicker"
import { Container } from "@mui/material"

export default function CustomerSubscriptionNew() {
    const [startDate, setStartDate] = useState<Date>(new Date())
    const location = useLocation()
    const navigate = useNavigate()
    const searchParams = new URLSearchParams(location.search)
    const customerId = searchParams.get("id")
    const formRef = useRef<any>(null)

    const [formFields, setFormFields] = useState([
        {
            name: "plan",
            label: "Plan",
            type: "select",
            placeholder: "Select plan",
            validation: {
                required: "Plan must be selected",
            },
        },
        {
            name: "billing_period",
            label: "Billing Period",
            type: "select",
            placeholder: "Select billing period",
            validation: {
                required: "Billing period required",
            },
            options: [
                { id: "MONTHLY", value: "Monthly" },
                { id: "ANNUAL", value: "Annually" },
            ],
        },
    ])

    useEffect(() => {
        fetchAllPlans()
    }, [])

    const fetchAllPlans = async () => {
        try {
            const result = await PlanAPI.getAllPlansNew()
            setFormFields((prevFields) =>
                prevFields.map((field) =>
                    field.name === "plan"
                        ? {
                            ...field,
                            options: result.map((plan: any) => ({
                                id: plan.id,
                                value: plan.name,
                            })),
                        }
                        : field
                )
            )
        } catch (error) {
            console.error("Error fetching plans:", error)
        }
    }

    const submitForm = async (data: Record<string, any>) => {
        const subscriptionPayload = {
            billing_cadence: "RECURRING",
            billing_period: data.billing_period,
            customer_id: customerId,
            start_date: startDate.toISOString(),
            plan_id: data.plan,
            billing_period_count: 1,
        }

        SubscriptionAPI.createSubscription(false, subscriptionPayload).then((response) => {
            if (response) {
                console.log("Subscription created successfully:", response)
                navigate(-1)
            }
        })
    }

    return (
        <Container>
            <div className="h-8"></div>
            <div className="w-full flex justify-between items-center">
                <h1 className="font-semibold text-[24px] bg-white ">Create Subscription</h1>
            </div>
            <div className="w-full border mt-2 mb-5"></div>
            <FormBuilder fields={formFields} onSubmit={submitForm} ref={formRef}>
                <div className="mb-4">
                    <FlexDatePicker
                        label="Start Date"
                        onDatePicked={(value) => setStartDate(value!)}
                        selectedDate={startDate}
                    />
                </div>
            </FormBuilder>
        </Container>
    )
}
